<template>
    <div class="table-pagination">
        <a-table
                v-bind="$attrs"
                :pagination="false"
                size="middle"
                :columns="columns"
                :loading="tableLoading"
        >
            <template
                    v-for="(column, index) in columns"
                    :slot="column.scopedSlots ? column.scopedSlots.customRender : ''"
                    slot-scope="text, record"
            >
                <slot
                        :name="column.scopedSlots ? column.scopedSlots.customRender : ''"
                        v-bind:scope="record"
                ></slot>
            </template>
            <template
                    v-for="slot in columns"
                    :slot="slot.slots ? slot.slots.title : ''"
            >
                <slot :name="slot.slots ? slot.slots.title : ''"></slot>
            </template>
        </a-table>
        <div class="footer-box" v-if="myPageShow">
            <Pagination
                    :page.sync="pagination.page"
                    :pageSize.sync="pagination.pageSize"
                    :total.sync="total"
                    @paginationChange="paginationChange"
            ></Pagination>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            tableLoading: {
                type: Boolean,
                default: false
            },
            total: {
                type: Number,
                default: 0,
            },
            columns: {
                type: Array,
                default: () => [],
            },
            myPageShow: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                pagination: {
                    page: 1,
                    pageSize: 50,
                },
            };
        },
        methods: {
            paginationChange(pageMsg) {
                this.$emit("changePagination", pageMsg);
            },
        },
        computed: {},
        mounted() {
        },
    };
</script>
<style lang="less" scoped>
    .table-pagination {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        /deep/ .ant-table {
            font-size: 12px;
        }

        .footer-box {
            width: 100%;
            padding: 12px;
        }
    }

    /deep/ .ant-table-body {
        // max-height: calc(100vh - 290px) !important;
    }

    /deep/ .ant-table-placeholder {
        height: calc(100vh - 290px) !important;
    }
</style>
