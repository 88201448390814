<template>
  <div>
    <a-modal
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
      title="选择成员"
      width="60%"
    >
      <a-radio-group v-model="value" class="w100 h100" v-if="!userList">
        <a-table
          size="middle"
          :columns="columns"
          :dataSource="list"
          :pagination="false"
          :scroll="{ x: true, y: 400 }"
        >
          <template slot="nameSlot" slot-scope="text, record">
            <div>
              <a-radio :value="record.id">
                {{ record.name }}
              </a-radio>
            </div>
          </template>
        </a-table>
      </a-radio-group>
      <a-checkbox-group v-model="multipleValue" class="w100 h100" v-else>
        <a-table
          size="middle"
          :columns="columns"
          :dataSource="list"
          :pagination="false"
          :scroll="{ x: true, y: 400 }"
        >
          <template slot="nameSlot" slot-scope="text, record">
            <div>
              <a-checkbox :value="record.id">
                {{ record.name }}
              </a-checkbox>
            </div>
          </template>
        </a-table>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import { getUserList } from "@/api/user";
export default {
  props: ["id", "name", "userList"],
  emits: ["update:id", "update:name"],
  data() {
    return {
      visible: false,
      columns: [
        {
          title: "成员姓名",
          dataIndex: "name",
          scopedSlots: { customRender: "nameSlot" },
        },
        {
          title: "手机号",
          dataIndex: "telPhone",
        },
      ],
      list: [],
      total: 0,
      value: "",
      multipleValue: [],
    };
  },
  watch: {
    userList(newv) {
      this.multipleValue = newv;
    },
  },
  methods: {
    // 打开弹窗
    openModal() {
      this.visible = true;
      this.getList();
    },
    // 获取设备列表
    getList() {
      getUserList({}).then((res) => {
        this.list = res.data;
      });
    },
    // 关闭弹窗
    handleCancel() {
      // this.$refs.form.resetFields()
      this.visible = false;
    },
    // 弹窗确认
    handleOk() {
      if (this.userList) {
        if (this.multipleValue.length == 0) {
          return this.$message.error({ content: "请选择成员!" });
        }
        this.$emit("update:userList", this.multipleValue);

        this.$emit(
          "onSubmit",
          this.list.filter((item) =>
            this.multipleValue.some((ele) => ele == item.id)
          )
        );
        this.visible = false;
      } else {
        if (!this.value) {
          return this.$message.error({ content: "请选择成员!" });
        }
        this.$emit("update:id", this.value);
        this.$emit(
          "update:name",
          this.list.filter((item) => {
            return item.id == this.value;
          })[0].name
        );
        // 选择回调
        this.$emit(
          "onSubmit",
          this.list.filter((item) => {
            return item.id == this.value;
          })[0]
        );
        this.value = "";
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>