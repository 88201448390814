import { requestToken } from '@/utils/request.js'
import service from '../utils/request'
import { getToken } from "../utils/auth";
// 获取用户列表
export const getUserList = (data) => {
  return requestToken('/user/userList', data)
}

// 获取当前用户信息
export const getCurrentUser = (data) => {
  return requestToken('/user/currentUser', data)
}

// 获取部门列表
export const getMerchantList = (data) => {
  return requestToken('/merchant/queryList', data)
}

// 获取部门详情
export const getMerchantDetail = (data) => {
  return requestToken('/merchant/querySingle', data)
}

// 添加/编辑部门 saveSingle 添加 updateSingle 编辑
export const createMerchant = (type, data) => {
  return requestToken(`/merchant/${type}`, data)
}

// 删除部门
export const delMerchant = (data) => {
  return requestToken('/merchant/deleteSingle', data)
}

// 获取部门成员列表
export const getDepartmentMembers = (data) => {
  return requestToken('/user/queryList', data)
}

// 获取部门成员详情
export const getDepartmentMembersDetail = (data) => {
  return requestToken('/user/querySingle', data)
}

// 添加/编辑成员 saveSingle 添加 updateSingle 编辑
export const createDepartmentMembers = (type, data) => {
  return requestToken(`/user/${type}`, data)
}

// 删除部门成员
export const delDepartmentMembers = (data) => {
  return requestToken('/user/deleteSingle', data)
}

// 重置密码部门成员
export const resetDepartmentMembersPassword = (data) => {
  return requestToken('/user/reset', data)
}


// 修改用户头像 /user/uploadIcon
export const userUploadIcon = (data) => {
  const dealData = new FormData()
  dealData.append("token", getToken());
  for (let key in data) {
    dealData.append(key, data[key])
  }
  return service({
    data: dealData,
    method: 'post',
    url: '/user/uploadIcon',
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
  // return requestToken('/user/uploadIcon', data)
}

// 重置密码部门成员
export const updateSingleUser = (data) => {
  return requestToken('/user/updateSingle', data)
}

// 修改密码 /user/setPassword
export const setPassword = (data) => {
  return requestToken('/user/setPassword', data)
}