const state = {
    menuKey: ['/'],
    settingKey: ['/setting/index']
}

const mutations = {
    updateMenuKey(state, v) {
        state.menuKey = [v]
    },
    updateSettingKey(state, v) {
        state.settingKey = [v]
    }
}
export default {
    namespaced: true,
    state,
    mutations
}