<template>
  <div>
    <a-drawer :bodyStyle="{
      padding: '0.3rem 0 0 0',
    }" :closable="false" :destroyOnClose="true" :headerStyle="{
      background: '#f0f1f5',
      padding: '0 0.2rem 0 0.3rem',
    }" :visible="visible" height="100%" placement="top" wrapClassName="add-power">
      <template #title>
        <div class="head-box">
          <span>请选择报警</span>
          <div class="head-btn">
            <a-button @click="visible=false">取消</a-button>
            <a-button class="save-btn" type="primary" @click="handleOk">保存</a-button>
          </div>
        </div>
      </template>
      <div class="content-box flex-directionc pd-20" style="background-color: none;">
        <div class="flex-alignc">
          <div>
            <a-input-search @search="onSearch" allow-clear placeholder="输入报警名称">
              <a-button icon="search" slot="enterButton"></a-button>
            </a-input-search>
          </div>
        </div>
        <div class="flex-sub1 mt-16 mb-16 scroll-y">
          <!-- 表格 -->
          <a-radio-group v-model="value" class="w100 h100">
            <myTable :columns="columns" :dataSource="list" :scroll="{ x: true, y: 420 }" :total="total"
                    @changePagination="getTableList">
              <template slot="alarmTitleSlot" slot-scope="slotData">
                <div>
                  <a-radio :value="slotData.scope.id">
                    {{ slotData.scope.alarmTitle }}
                  </a-radio>
                </div>
              </template>
            </myTable>
          </a-radio-group>
        </div>

      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getAlarmList } from '@/api/workOrder'
export default {
  props: ['id', 'name', 'deviceSn'],
  emits: ['update:id', 'update:name'],
  data () {
    return {
      visible: false, // 选择电站弹窗
      queryParams: {
        page: 1,
        pageSize: 50,
        policeName: ''
      },
      columns: [{
        title: '报警名称',
        dataIndex: "alarmTitle",
        width: 120,
        scopedSlots: { customRender: 'alarmTitleSlot' }
      }
        // {
        //   title: '状态',
        //   width: 120
        // },
        // {
        //   title: '所属父设备',
        //   width: 120
        // },
        // {
        //   title: '所属电站',
        //   width: 120
        // }
      ],
      list: [],
      total: 0,
      value: '',
    };
  },

  methods: {
    onSearch () { },
    // 分页切换 获取表格数据
    getTableList (pageMsg) { },
    // 打开弹窗
    openModal () {
      this.visible = true
      this.getList()
    },
    // 获取设备列表
    getList () {
      getAlarmList({
        deviceSn: this.deviceSn
      }).then(res => {
        this.list = res.data
      })
    },
    // 弹窗确认
    handleOk () {
      if (!this.value) {
        return this.$message.error({ content: '请选择设备!' })
      }
      this.$emit('update:id', this.value)
      this.$emit('update:name', this.list.filter(item => { return item.id == this.value })[0].alarmTitle)
      // 选择回调
      this.$emit('onSubmit', this.list.filter(item => { return item.id == this.value })[0])
      this.visible = false
    }
  },
};
</script>

<style lang="less" scoped>
.add-power {
  .head-box {
    width: 100%;
    height: 64px;
    font-size: 20px;
    color: #252b3a;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-btn {
      /deep/.ant-btn {
        font-size: 12px;
      }

      .save-btn {
        margin-left: 8px;
      }
    }
  }

  /deep/.ant-drawer-body {
    height: calc(100vh - 100px);
  }

  .content-box {
    width: 100%;
    height: 100%;
    padding-top: 24px;

    .left {
      padding-right: 10px;

      /deep/.ant-form label {
        font-size: 12px;
        color: #00000073;
      }

      /deep/.ant-form-item-label {
        line-height: initial !important;
      }

      /deep/.ant-form-item {
        margin-bottom: 12px;
      }

      .tagBox-wrap {
        position: relative;
        border: 1px solid #d9d9d9;
        line-height: initial;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .flow {
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);

      li {
        position: relative;
        padding: 0 40px 60px 40px;

        &:before {
          position: absolute;
          display: block;
          content: "";
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          background-color: hsla(0, 0%, 50.2%, 0.6);
          border-radius: 50%;
        }

        &:after {
          position: absolute;
          display: block;
          content: "";
          top: 8px;
          bottom: -3px;
          left: 7px;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 1px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        .tip {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .header {
          position: relative;
          margin-bottom: 10px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }

          .close {
            position: absolute;
            top: -20%;
            right: -20%;
          }
        }
      }
    }
  }
}
</style>