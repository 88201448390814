<template>
  <a-dropdown overlayClassName="select-box" v-bind="$attrs">
    <a-menu slot="overlay">
      <a-menu-item @click="chooseOne(item.value)" v-for="item in dropdownList">
        <div :class="defaultFlag == item.value ? 'select-one' : ''">
          {{ item.label }}
        </div>
      </a-menu-item>
    </a-menu>
    <a-button class="btn-style" size="small">
      {{ statusName }} <a-icon type="down" />
    </a-button>
  </a-dropdown>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dropdownList: {
      type: Array,
      default: () => [],
    },
    defaultFlag: {
      type: [Number, String],
      default: 0,
    },
    // 第一个
    specialText: {
      type: String,
      default: "",
    },
  },
  methods: {
    chooseOne(index) {
      console.log(index);
      this.$emit("update:defaultFlag", index);
      // this.$emit("chooseItem", index);
    },
  },
  computed: {
    statusName() {
      let findValue = this.dropdownList.find((item) => {
        return item.value == this.defaultFlag;
      });
      if (this.specialText) {
        if (findValue.value == 0) {
          return this.specialText;
        } else {
          return findValue?.label;
        }
      } else {
        return findValue?.label;
      }
    },
  },
};
</script>
<style lang="less" scoped>
:global(.select-box .ant-dropdown-menu-item) {
  height: auto;
  padding: 0px;
}
:global(.select-box .ant-dropdown-menu-item div) {
  // height: 24px;
  padding: 14px;
  font-size: 12px;
  // line-height: 16px;
  // line-height: 1;
  display: flex;
  align-items: center;
  color: #252b3a;
}
:global(.select-box .ant-dropdown-menu-item .select-one) {
  background: #048fff;
  color: #fff;
}
.btn-style {
  font-size: 12px;
  &:hover {
    color: #048fff;
  }
}
</style>