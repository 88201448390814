import { requestToken } from '@/utils/request.js'

// 获取待办列表 /notice/queryList
export const noticeList = (data) => {
    return requestToken('/notice/queryList', data)
}

// 整体发电概况 /dashboard/powerOverview
export const powerOverview = (data) => {
    return requestToken('/dashboard/powerOverview', data)
}

// 整体发电历史 /dashboard/allPowerHistory
export const allPowerHistory = (data) => {
    return requestToken('/dashboard/allPowerHistory', data)
}

// 电站状态简报 /dashboard/stationStatus
export const stationStatus = (data) => {
    return requestToken('/dashboard/stationStatus', data)
}

// 电站分布 /dashboard/stationDistribution
export const stationDistribution = (data) => {
    return requestToken('/dashboard/stationDistribution', data)
}

// 功率归一化排名 /dashboard/dashboardPower
export const dashboardPower = (data) => {
    return requestToken('/dashboard/dashboardPower', data)
}

// 日满发小时排名 /dashboard/dashboardFullHour
export const dashboardFullHour = (data) => {
    return requestToken('/dashboard/dashboardFullHour', data)
}

// 已完成工单偏差排名  /dashboard/dashboardTaskDeviation
export const dashboardTaskDeviation = (data) => {
    return requestToken('/dashboard/dashboardTaskDeviation', data)
}

// 工单状态简报 /dashboard/dashboardTaskStatus
export const dashboardTaskStatus = (data) => {
    return requestToken('/dashboard/dashboardTaskStatus', data)
}

// 未完成工单超时排名 /dashboard/dashboardTaskIncomplete
export const dashboardTaskIncomplete = (data) => {
    return requestToken('/dashboard/dashboardTaskIncomplete', data)
}

// 工单处理人排名 /dashboard/dashboardHandlerRanking
export const dashboardHandlerRanking = (data) => {
    return requestToken('/dashboard/dashboardHandlerRanking', data)
}

// 获取大屏顶部nav数据
export const stationInfo = (data) => {
    return requestToken('/screen/stationInfo', data)
}
// 获取当月新增工单 /dashboard/dashboardAddTaskTrend
export const dashboardAddTaskTrend = (data) => {
    return requestToken('/dashboard/dashboardAddTaskTrend', data)
}

