import service from '@/utils/request.js'

export const loginApi = (data) => {
    return service({
        data,
        method: 'post',
        url: 'user/querySingle',
        headers: {
            isToken: false
        },
    })
}
