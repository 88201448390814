<template>
  <div class="main-box">
    <a-config-provider :locale="zh_CN">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      zh_CN,
    };
  },
};
</script>
<style lang="less" scoped>
.main-box {
  width: 100%;
  height: 100%;
  background: #f0f0f1;
  // padding-left: 20px;
  padding: 0 0 20px 20px;
  box-sizing: border-box;
}
</style>