import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'
import stationLayout from '../station/layout/index.vue'
import routerView from '@/station/layout/routerView.vue'
import settingLayout from '@/layout/settingIndex.vue'

Vue.use(Router)


export let asyncRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                meta: {
                    title: '数据看板',
                    icon: 'dashboard'
                },
                component: () => import('@/views/home.vue')
            },
            // 监控
            {
                path: '/monitoring',
                component: routerView,
                meta: {
                    title: '监控',
                    icon: 'desktop'
                },
                children: [
                    {
                        path: '/monitoring/powerstation',
                        component: () => import('@/views/monitoring/powerstation.vue'),
                        meta: {
                            title: '电站'
                        }
                    },
                    {
                        path: '/monitoring/equipment',
                        component: () => import('@/views/monitoring/device.vue'),
                        meta: {
                            title: '设备'
                        }
                    },
                    {
                        path: '/monitoring/alarm',
                        component: () => import('@/views/monitoring/alarm.vue'),
                        meta: {
                            title: '报警',
                        }
                    },
                ]
            },
            // 应用
            {

                path: '/apply/index',
                name: 'apply',
                meta: {
                    title: '应用',
                    icon: 'shop'
                },
                component: () => import('@/views/apply/index.vue')
            },
            // 管理
            {
                path: 'manage',
                component: routerView,
                meta: {
                    title: '管理',
                    icon: 'setting'
                },
                children: [
                    {
                        path: '/manage/aboutMerchant',
                        component: () => import('@/views/manage/aboutMerchant.vue'),
                        meta: {
                            title: '关于商家'
                        },
                    },
                    {
                        path: '/manage/section',
                        component: () => import('@/views/manage/section'),
                        meta: {
                            title: '部门/成员'
                        },
                    },
                    {
                        path: '/manage/role',
                        component: () => import('@/views/manage/role'),
                        meta: {
                            title: '角色'
                        },
                    },
                ]
            }
        ],
    }
]

export let settingRoutes = [
    {
        path: '/setting',
        component: settingLayout,
        children: [
            {
                path: '/setting/index',
                meta: {
                    title: '个人信息',
                    icon: 'user'
                },
                component: () => import('@/setting/index.vue')
            },
        ]
    }
]


export let stationAsyncRoutes = [
    {
        path: '/station',
        component: stationLayout,
        meta: {
            title: '监控',
            icon: 'desktop'
        },
        children: [
            {
                path: '/station/main',
                meta: {
                    title: '数据看板',
                    icon: 'dashboard'
                },
                component: () => import('../station/main.vue')
            },
            {
                path: "/station/device",
                meta: {
                    title: "设备",
                    icon: "dashboard",
                },
                component: () => import("../station/device.vue"),
            },
            {
                path: '/station/power',
                redirect: '/station/power/basic',
                meta: {title: '关于电站', icon: 'dashboard'},
                component: routerView,
                children: [
                    {
                        path: "/station/power/basic",
                        meta: {
                            title: "电站信息",
                            icon: "dashboard",
                        },
                        component: () => import("../station/power/basic.vue"),
                    },
                    {
                        path: "/station/power/photo",
                        meta: {
                            title: "照片",
                            icon: "dashboard",
                        },
                        component: () => import("../station/power/photo.vue"),
                    },
                ]
            },
        ],
    }
]

const routes = [
    {
        path: '/login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/register/:id',
        component: () => import('../views/register/index.vue')
    },
    {
        path: '/apply/screen',
        component: () => import('../views/apply/screen.vue'),
        meta: {
            title: '大屏'
        }
    },
    {
        path: '/apply/screen2',
        component: () => import('../views/apply/screenSecond.vue'),

    },
    {
        path: '/apply/screen3',
        component: () => import('../views/apply/screenThird.vue'),
    },
    {
        path: '/apply/screen4',
        component: () => import('../views/apply/screenFourth.vue'),
    },
    {
        path: '/apply/screenList',
        component: Layout,
        meta: {
            title: '大屏'
        },
        children: [
            {component: () => import('../views/apply/screenList.vue'), path: '/apply/screenList',}
        ]
    },
    // ...asyncRoutes,
    ...settingRoutes,
    ...stationAsyncRoutes,

]
let routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((err) => err)
}
// export asyncRoutes;
export default new Router({
    mode: 'hash', // url带#
    // mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({
        y: 0
    }),
    routes,
})
