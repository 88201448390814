import { requestToken } from '@/utils/request.js'

// 获取工单列表
export const getTaskList = (data) => {
  return requestToken('/task/queryList', data)
}

// 获取工单详情
export const getTaskDetail = (data) => {
  return requestToken('/task/querySingle', data)
}

// 添加/编辑工单 type saveSingle 添加  updateSingle 编辑
export const createTask = (type,data) => {
  return requestToken(`/task/${type}`, data)
}

// 操作工单
// 执行人接受工单 acceptTask
// 执行人拒绝工单 refuseTask
// 执行人执行完成 executeTask
// 验证人同意工单 agreeTask
// 验证人拒绝工单 refuseVerifyTask
// 验证人评价工单 evaluateTask
export const operateTask = (type,data) => {
  return requestToken(`/task/${type}`, data)
}

// 获取工单详情评价
export const getTaskEvaluate = (data) => {
  return requestToken('/taskEvaluate/querySingle', data)
}

// 提交工单报告
export const taskReport = (data) => {
  return requestToken('/taskReport/saveList', data)
}
// 查看工单报告
export const getTaskReport = (data) => {
  return requestToken('/taskReport/queryList', data)
}





// 获取电站列表
export const getStationList = (data) => {
  return requestToken('/station/stationList', data)
}

// 获取设备列表
export const getDeviceList = (data) => {
  return requestToken('/device/deviceList', data)
}

// 获取报警列表
export const getAlarmList = (data) => {
  return requestToken('/alarm/alarmList', data)
}
