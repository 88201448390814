<template>
  <div>
    <a-drawer
      :bodyStyle="{
        padding: '0.3rem 0 0 0',
      }"
      :closable="false"
      :destroyOnClose="true"
      :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
      }"
      :visible="visible"
      height="100%"
      placement="top"
      wrapClassName="add-power"
    >
      <template #title>
        <div class="head-box">
          <span>选择设备</span>
          <div class="head-btn">
            <a-button @click="visible = false">取消</a-button>
            <a-button class="save-btn" type="primary" @click="handleOk"
              >保存</a-button
            >
          </div>
        </div>
      </template>
      <div
        class="content-box flex-directionc pd-20"
        style="background-color: none"
      >
        <div class="flex-alignc">
          <div>
            <a-input-search
              @search="onSearch"
              allow-clear
              placeholder="输入设备SN号"
            >
              <a-button icon="search" slot="enterButton"></a-button>
            </a-input-search>
          </div>
          <!-- <a-icon class="ml-16" type="filter" /> -->
        </div>
        <div class="flex-sub1 mt-16 mb-16">
          <!-- 表格 -->
          <a-radio-group v-model="value" class="w100 h100" v-if="!deviceList">
            <myTable
              :columns="columns"
              :dataSource="list"
              :scroll="{ x: true, y: yHeight }"
              :total="total"
              :myPageShow="false"
              @changePagination="getTableList"
            >
              <template slot="deviceSnSlot" slot-scope="slotData">
                <a-radio :value="slotData.scope.deviceSn">
                  <span>{{ slotData.scope.deviceSn }}</span>
                </a-radio>
              </template>
            </myTable>
          </a-radio-group>
          <a-checkbox-group v-model="multipleValue" class="w100 h100" v-else>
            <myTable
              :columns="columns"
              :dataSource="list"
              :scroll="{ x: true, y: yHeight }"
              :total="total"
              :myPageShow="false"
              @changePagination="getTableList"
            >
              <template slot="deviceSnSlot" slot-scope="slotData">
                <div>
                  <a-checkbox :value="slotData.scope.deviceSn">
                    {{ slotData.scope.deviceSn }}
                  </a-checkbox>
                </div>
              </template>
            </myTable>
          </a-checkbox-group>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getDeviceList } from "@/api/workOrder";
import { debound } from "@/utils/index.js";
export default {
  props: ["id", "name", "stationId", "deviceList"],
  emits: ["update:id", "update:name"],
  data() {
    return {
      visible: false, // 选择电站弹窗
      yHeight:
        document.documentElement.clientHeight -
        document.documentElement.clientHeight / 5.14,
      queryParams: {
        page: 1,
        pageSize: 50,
        sn: "",
      },
      columns: [
        {
          title: "设备SN",
          dataIndex: "deviceSn",
          width: 240,
          scopedSlots: { customRender: "deviceSnSlot" },
        },
        {
          title: "设备名称",
          dataIndex: "deviceTitle",
          width: 120,
        },
        {
          title: "父设备SN",
          dataIndex: "parentId",
          width: 120,
        },
        {
          title: "所属父设备",
          dataIndex: "parentName",
          width: 120,
        },
        {
          title: "所属电站",
          dataIndex: "stationName",
          width: 120,
        },
      ],
      list: [],
      total: 0,
      value: "",
      multipleValue: [],
    };
  },
  watch: {
    deviceList(newv) {
      this.multipleValue = newv;
    },
  },
  methods: {
    onSearch() {},
    // 分页切换 获取表格数据
    getTableList(pageMsg) {},
    // 打开弹窗
    openModal() {
      this.visible = true;
      this.getList();
    },
    // 获取设备列表
    getList() {
      getDeviceList({
        stationId: this.stationId,
      }).then((res) => {
        this.list = res.data;
        this.total = res.data.length;
      });
    },
    // 弹窗确认
    handleOk() {
      if (this.deviceList) {
        if (this.multipleValue.length == 0) {
          return this.$message.error({ content: "请选择设备!" });
        }
        this.$emit("update:deviceList", this.multipleValue);

        this.$emit(
          "onSubmit",
          this.list.filter((item) =>
            this.multipleValue.some((ele) => ele == item.deviceSn)
          )
        );
        this.visible = false;
      } else {
        if (!this.value) {
          return this.$message.error({ content: "请选择设备!" });
        }
        this.$emit("update:id", this.value);
        this.$emit(
          "update:name",
          this.list.filter((item) => {
            return item.deviceSn == this.value;
          })[0].deviceTitle
        );
        // 选择回调
        this.$emit(
          "onSubmit",
          this.list.filter((item) => {
            return item.deviceSn == this.value;
          })[0]
        );
        this.visible = false;
      }
    },
    watchResize() {
      window.addEventListener("resize", this.screenResize);
    },
    screenResize: debound(function () {
      // document.documentElement.clientHeight;
      this.yHeight =
        document.documentElement.clientHeight -
        document.documentElement.clientHeight / 5.14;
    }, 200),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.screenResizec);
  },
  mounted() {
    this.watchResize();
    console.log(this.deviceList, "----------");
  },
};
</script>

<style lang="less" scoped>
.add-power {
  .head-box {
    width: 100%;
    height: 64px;
    font-size: 20px;
    color: #252b3a;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-btn {
      /deep/.ant-btn {
        font-size: 12px;
      }

      .save-btn {
        margin-left: 8px;
      }
    }
  }

  /deep/.ant-drawer-body {
    height: calc(100vh - 100px);
  }

  .content-box {
    width: 100%;
    height: 100%;
    padding-top: 24px;

    .left {
      padding-right: 10px;

      /deep/.ant-form label {
        font-size: 12px;
        color: #00000073;
      }

      /deep/.ant-form-item-label {
        line-height: initial !important;
      }

      /deep/.ant-form-item {
        margin-bottom: 12px;
      }

      .tagBox-wrap {
        position: relative;
        border: 1px solid #d9d9d9;
        line-height: initial;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .flow {
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);

      li {
        position: relative;
        padding: 0 40px 60px 40px;

        &:before {
          position: absolute;
          display: block;
          content: "";
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          background-color: hsla(0, 0%, 50.2%, 0.6);
          border-radius: 50%;
        }

        &:after {
          position: absolute;
          display: block;
          content: "";
          top: 8px;
          bottom: -3px;
          left: 7px;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 1px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        .tip {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .header {
          position: relative;
          margin-bottom: 10px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }

          .close {
            position: absolute;
            top: -20%;
            right: -20%;
          }
        }
      }
    }
  }
}
</style>