import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '../router'
import store from '../store'
import { getToken } from './auth'
import { asyncRoutes } from '../router/index'
const whiteList = ['/login', '/register']
let flag = 0
router.beforeEach((to, from, next) => {
    NProgress.start()
    if (getToken()) {
        /* has token*/
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {

            if (store.state.User.menus.length == 0 || store.state.User.menus.length != 0 && flag == 0) {
                let roleMenu = store.state.User.uerInfo.menuEntities
                let roleList = []
                roleMenu.forEach(item => {
                    roleList.push(item.menuName)
                    if (item.menuEntities) {
                        item.menuEntities.forEach(child => {
                            roleList.push(child.menuName)
                        })
                    }
                })
                let dealRoutes = asyncRoutes.filter(parent => {
                    parent.children.filter((item, index) => {
                        if (item.meta.title == '数据看板' || roleList.includes(item.meta.title)) {
                            if (item.children) {
                                item.children = item.children.filter((child, childIndex) => {
                                    if (roleList.includes(child.meta.title)) {
                                        return child
                                    }
                                })
                            }
                            return item
                        }
                    })
                    return true
                })
                flag++
                store.commit('User/SET_MENUS', dealRoutes || [])
                router.addRoutes([...dealRoutes, {
                    path: '*',
                    redirect: '/'
                }])

                next({ ...to, replace: true })

            } else {
                next()
            }


        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1 || to.path.split('/')[1] == 'register') {
            // 在免登录白名单，直接进入
            next()

        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            NProgress.done()
        }
    }
    // next()
})
router.afterEach(() => {
    NProgress.done()
})