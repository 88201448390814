<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
@import url("./styles/global.less");
#app {
  width: 100%;
  height: 100vh;
}
</style>
