<template>
  <div class="content" :class="collapsed ? 'width-min' : ''">
    <a-menu
      :open-keys.sync="openKeys"
      v-model="selectKey"
      mode="inline"
      class="menu-box"
      :inline-collapsed="collapsed"
      @click="handleClick"
    >
      <template v-for="menu in routes">
        <a-sub-menu :key="menu.path" v-if="menu.children">
          <span slot="title"
            ><a-icon :type="menu.meta.icon" /><span>{{
              menu.meta.title
            }}</span></span
          >
          <a-menu-item
            :key="menuChild.path"
            v-for="menuChild in menu.children"
            @click="toPath(menuChild.path)"
          >
            {{ menuChild.meta.title }}
          </a-menu-item>
        </a-sub-menu>
        <!-- <a-menu-item
          :key="menu.path"
          v-else-if="menu.children && menu.children.length == 1"
          @click="toPath(menu.children[0].path)"
        >
          <a-icon :type="menu.children[0].meta.icon" />
          {{ menu.children[0].meta.title }}
        </a-menu-item> -->
        <a-menu-item :key="menu.path" v-else @click="toPath(menu.path)">
          <a-icon :type="menu.meta.icon" />
          {{ menu.meta.title }}
        </a-menu-item>
      </template>
    </a-menu>
    <div class="collpase" @click="toggleCollapsed">
      <a-icon type="vertical-right" />
    </div>
  </div>
</template>
<script>
import { asyncRoutes } from "../router/index";
export default {
  data() {
    return {
      openKeys: [""],
      asyncRoutes,
      collapsed: false,
      selectKey: [],
    };
  },
  methods: {
    handleClick({ key }) {
      this.$store.commit("Menu/updateMenuKey", key);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    toPath(path) {
      if (path == "/monitoring/thermalImaging") {
        window.open("http://222.195.120.160:8081/#/zhjksx/video", "_blank");
        return;
      } else if (path == "/monitoring/drone") {
        window.open(
          "http://222.195.120.162:8080/pvs/#/new-power-station/4b60d248257c496e870bdeb989ea648c",
          "_blank"
        );
        return;
      }
      this.$router.push(path);
    },
  },
  computed: {
    routes() {
      this.selectKey = [this.$route.path];
      this.$store.commit("Menu/updateMenuKey", this.$route.path);
      return this.$store.state.User.menus[0]?.children || [];
    },
  },
  mounted() {
    console.log(this.$store.state.User.menus);
    this.selectKey = this.$store.state.Menu.menuKey;
    if (this.selectKey[0] != this.$route.path) {
      this.selectKey = [this.$route.path];
      this.$store.commit("Menu/updateMenuKey", this.$route.path);
    }
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 200px;
  height: 100%;
  transition: all 0.2s;
  position: relative;
  .menu-box {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .collpase {
    position: absolute;
    width: 12px;
    height: 66px;
    font-size: 12px;
    z-index: 2;
    color: #048fff;
    -webkit-clip-path: polygon(0 0, 100% 9%, 100% 93%, 0 100%);
    clip-path: polygon(0 0, 100% 9%, 100% 93%, 0 100%);
    background: #b9d7f1;
    right: -12px;
    top: 50%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background: #82c0f4;
      color: #fff;
    }
  }
  .ant-menu-item {
    font-size: 12px;
  }
  .ant-menu-submenu {
    /deep/.ant-menu-submenu-title {
      font-size: 12px;
    }
  }
  /deep/.ant-menu-inline-collapsed {
    width: 80px;
    overflow: hidden;
  }
  /deep/ .anticon {
    margin-right: 20px;
  }
  // background: #bfa;
}
.width-min {
  width: 80px;
}
</style>