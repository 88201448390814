<template>
  <div>
    <a-modal :visible="visible" @cancel="handleCancel" @ok="handleOk" title="请选择工单模板">
      <a-form-model ref="form">
        <a-form-model-item>
          <a-radio-group v-model="value">
            <div class="template-list flex-wrap">
              <div class="item" v-for="(item,index) in list" :key="index">
                <a-radio :value="item.id">
                  <img class="img" src="@/assets/workOrder.svg" />
                  <div class="item-name text-center">{{ item.taskName }}</div>
                </a-radio>
              </div>
            </div>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getTemplateList } from '@/api/template'
export default {
  props: ['id','name'],
  emits: ['update:id','update:name'],
  data () {
    return {
      visible: false,
      value:'',
      list: [], // 列表
    }
  },
  methods: {
    // 打开弹窗
    openModal () {
      this.visible = true
      this.value = this.id
      this.getList()
    },
    // 关闭弹窗
    handleCancel () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    // 获取模板列表
    getList () {
      getTemplateList({}).then(res => {
        this.list = res.data
      })
    },
    // 弹窗确认
    handleOk () {
      if(!this.value) {
        return this.$message.error({ content: '请选择模板!'})
      }
      this.$emit('update:id', this.value)
      this.$emit('update:name', this.list.filter(item => { return item.id==this.value })[0].taskName)
      // 选择回调
      this.$emit('onSubmit', this.list.filter(item => { return item.id==this.value })[0])
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.template-list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding-top: 10px;

  .item {
    position: relative;
    text-align: center;
    width: 200px;
    margin-right: 20px;

    /deep/ .ant-radio {
      position: absolute;
      top: -6px;
      right: -12px;
    }

    .img {
      width: 80px;
      height: 80px;
    }

    .item-name {
      white-space: pre-wrap;
    }
  }
}
</style>