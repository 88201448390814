<template>
  <a-drawer
    placement="right"
    :visible="visible"
    @close="onClose"
    width="900"
    :closable="false"
    :headerStyle="{ borderBottom: 'none', paddingBottom: '0' }"
    :bodyStyle="{
      background: '#EFEFF0',
      height: '100%',
      padding: '16px 12px 20px 12px',
    }"
    :drawerStyle="{ display: 'flex', flexDirection: 'column' }"
  >
    <template #title>
      <div class="top-box">
        <div class="header">
          <span>待办事项</span>
          <div class="right-operation">
            <a-icon type="sync" class="reload-icon" />
            <div class="close-box" @click="onClose">
              <a-icon type="close" />
            </div>
          </div>
        </div>
        <div class="tabs">
          <div
            class="one-tab"
            v-for="tab in tabList"
            :class="
              (tab.value != 0 ? 'ml-8' : '',
              tab.value == activeIndex ? 'active-tab' : '')
            "
            @click="changeTab(tab.value)"
          >
            <a-badge
              count="0"
              v-if="tab.showBadge"
              class="bage"
              :numberStyle="{
                minHeight: '14px',
                width: '14px',
              }"
              :offset="[6, -4]"
            >
              <span>{{ tab.label }}</span>
            </a-badge>
            <span v-else>{{ tab.label }}</span>
          </div>
        </div>
      </div>
    </template>
    <div class="content">
      <div class="search-box">
        <div class="left-search-box">
          <a-dropdown placement="bottomLeft" overlayClassName="select-box">
            <a-menu slot="overlay">
              <a-menu-item
                @click="chooseOne(status.value, 'statusFlag')"
                v-for="status in statusList"
              >
                <div :class="statusFlag == status.value ? 'select-one' : ''">
                  {{ status.label }}
                </div>
              </a-menu-item>
            </a-menu>
            <a-button style="font-size: 12px" size="small">
              {{ statusName }} <a-icon type="down" />
            </a-button>
          </a-dropdown>
          <a-dropdown placement="bottomLeft" overlayClassName="select-box">
            <a-menu slot="overlay">
              <a-menu-item
                @click="chooseOne(overtime.value, 'overtimeStatusFlag')"
                v-for="overtime in overtimeStatusList"
              >
                <div
                  :class="
                    overtimeStatusFlag == overtime.value ? 'select-one' : ''
                  "
                >
                  {{ overtime.label }}
                </div>
              </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px; font-size: 12px" size="small">
              {{ overtimeStatusName }} <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </div>
        <a-input-search
          placeholder="输入标题查询"
          @search="onSearch"
          allow-clear
          style="width: 240px"
          size="small"
        >
          <a-button slot="enterButton" icon="search"> </a-button>
        </a-input-search>
      </div>
      <div class="">
        <!-- 表格 -->
        <myTable
            :columns="columns"
            :dataSource="data"
            :scroll="{ x: true, y: 400 }"
            :total="total"
            @changePagination="getList"
          >
        </myTable>
        <!-- <a-table
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :scroll="{ y: 240 }"
        >
        </a-table>
        <div class="mt-16">
          <Pagination
            :page.sync="pagination.page"
            :pageSize.sync="pagination.pageSize"
            :total.sync="pagination.total"
            @paginationChange="paginationChange"
          ></Pagination>
        </div> -->
        
      </div>
    </div>
  </a-drawer>
</template>
<script>
// import Pagination from "../../components/pagination.vue";
import {
  noticeList
} from "@/api/dataBoard.js";
export default {
  data() {
    return {
      visible: false,
      tabList: [
        {
          value: 0,
          label: "待我处理",
          showBadge: true,
        },
        {
          value: 1,
          label: "我执行中的",
          showBadge: true,
        },
        {
          value: 2,
          label: "我已处理的",
        },
        {
          value: 3,
          label: "我发起的",
        },
        {
          value: 4,
          label: "抄送我的",
        },
      ],
      activeIndex: 0,
      columns: [
        {
          title: "标题",
          dataIndex: "title",
        },
        {
          title: "状态",
        },
        {
          title: "超时状态",
        },
        {
          title: "更新时间",
        },
      ],
      data: [],

      total: 0,
      // pagination: {
      //   page: 1,
      //   pagesize: 50,
      //   total: 100,
      // },
      // 状态列表
      statusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待接受",
        },
        {
          value: 2,
          label: "待处理",
        },
        {
          value: 3,
          label: "执行中",
        },
        {
          value: 4,
          label: "待验证",
        },
        {
          value: 5,
          label: "待评价",
        },
        {
          value: 6,
          label: "已完成",
        },
        {
          value: 7,
          label: "拒绝接受",
        },
        {
          value: 8,
          label: "已撤销",
        },
      ],
      statusFlag: 0,
      // 超时状态列表
      overtimeStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "已超时",
        },
        {
          value: 2,
          label: "未超时",
        },
      ],
      overtimeStatusFlag: 0,
    };
  },
  // components: { Pagination },
  props: {
    waitShow: Boolean,
  },
  methods: {
    getList(pageMsg) {
      let requestPage = {
        page: 1,
        limit: 50,
      };
      if (pageMsg?.page) {
        requestPage.page = pageMsg.page;
        requestPage.limit = pageMsg.pageSize;
      }
      noticeList({ ...this.queryParams, ...requestPage }).then(res => {
        // this.data = res.data;
        // this.total = res.count;
      })
    },
    onClose() {
      this.visible = false;
      this.$emit("waitClose");
    },
    changeTab(index) {
      if (index == this.activeIndex) return;
      this.activeIndex = index;
    },
    onSearch() {},
    paginationChange(v) {
      console.log(v, this.pagination);
    },
    chooseOne(index, variable) {
      this[variable] = index;
    },
  },
  watch: {
    waitShow(newv) {
      this.visible = newv;
    },
  },
  computed: {
    statusName() {
      let findValue = this.statusList.find((item) => {
        return item.value == this.statusFlag;
      });
      if (findValue.value == 0) {
        return "状态";
      } else {
        return findValue.label;
      }
    },

    overtimeStatusName() {
      let findValue = this.overtimeStatusList.find((item) => {
        return item.value == this.overtimeStatusFlag;
      });
      if (findValue.value == 0) {
        return "超时状态";
      } else {
        return findValue.label;
      }
    },
  },
  mounted() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
.top-box {
  .header {
    height: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      color: #252b3a;
    }
    .right-operation {
      display: flex;
      //   justify-content: space-between;
      align-items: center;
      .reload-icon {
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: #048fff;
        }
      }
      .close-box {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-left: 16px;
        background: #ff7875;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          i {
            transform: rotate(90deg);
          }
        }
        i {
          color: #fff;
          transition: all 0.2s;
        }
      }
    }
  }
  .tabs {
    margin-top: 16px;
    // height: 30px;
    // background: #bfa;

    display: flex;
    .one-tab {
      padding: 12px 0 8px 0;
      margin-right: 24px;
      font-size: 12px;
      cursor: pointer;
      color: #00000073;
      span {
        font-size: 12px;
        color: #00000073;
      }
      &:hover span {
        color: #048fff;
      }
      .bage {
        font-size: #fff;
        /deep/.ant-badge-count {
          min-width: 13px !important;
          height: 13px !important;
          line-height: 13px;
          padding: 0 4px;
        }
      }
    }
    .active-tab {
      border-bottom: 2px solid #048fff;
      span {
        color: #048fff;
      }
    }
    .ml-8 {
      margin-left: 8px;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 16px;

  box-sizing: border-box;
  .search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
:global(.select-box .ant-dropdown-menu-item) {
  height: 24px;
  padding: 0px;
}
:global(.select-box .ant-dropdown-menu-item div) {
  height: 24px;
  padding: 2px 14px;
  font-size: 12px;
  line-height: 20px;
  color: #252b3a;
}
:global(.select-box .ant-dropdown-menu-item .select-one) {
  background: #048fff;
  color: #fff;
}
</style>