import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Menu from './modules/menu'
import User from './modules/uer'
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        Menu,
        User
    },
    plugins: [createPersistedState()]
})
export default store;