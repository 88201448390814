<template>
  <div>
    <a-drawer
      :bodyStyle="{
        padding: '0.3rem 0 0 0',
      }"
      :closable="false"
      :destroyOnClose="true"
      :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
      }"
      :visible="visible"
      height="100%"
      placement="top"
      wrapClassName="add-power"
    >
      <template #title>
        <div class="head-box">
          <span>选择电站</span>
          <div class="head-btn">
            <a-button @click="visible = false">取消</a-button>
            <a-button class="save-btn" type="primary" @click="handleOk"
              >保存</a-button
            >
          </div>
        </div>
      </template>
      <div
        class="content-box flex-directionc pd-20"
        style="background-color: none"
      >
        <div class="flex-alignc">
          <div>
            <a-input-search
              @search="onSearch"
              allow-clear
              placeholder="输入电站名称"
            >
              <a-button icon="search" slot="enterButton"></a-button>
            </a-input-search>
          </div>
          <a-button type="link" icon="filter" size="small"> 高级筛选 </a-button>
        </div>
        <div class="flex-sub1 mt-16 mb-16 scroll-y">
          <a-radio-group v-model="value" v-if="!stationList">
            <a-radio
              v-for="(item, index) in list"
              :key="index"
              :value="item.id"
              :style="{ display: 'block' }"
            >
              {{ item.stationName }}
            </a-radio>
          </a-radio-group>
          <a-checkbox-group v-model="multipleValue" class="w100 h100" v-else>
            <a-checkbox
              v-for="(item, index) in list"
              :key="index"
              :value="item.id"
              :style="{ display: 'block' }"
            >
              {{ item.stationName }}
            </a-checkbox>
          </a-checkbox-group>
        </div>

        <!-- <Pagination :page.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" :total.sync="total"
                    @paginationChange="paginationChange"></Pagination> -->
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getStationList } from "@/api/workOrder";
export default {
  props: ["id", "name", "stationList"],
  emits: ["update:id", "update:name"],
  data() {
    return {
      visible: false, // 选择电站弹窗
      queryParams: {
        page: 1,
        pageSize: 50,
        stationName: "",
      },
      total: 0,
      value: "",
      list: [], // 列表
      multipleValue: [],
    };
  },
  watch: {
    stationList(newv) {
      this.multipleValue = newv;
    },
  },
  methods: {
    onSearch() {},
    // 选择电站分页切换
    paginationChange(pageMsg) {},
    // 打开弹窗
    openModal() {
      this.visible = true;
      this.value = this.id;
      this.getList();
    },
    // 获取电站列表
    getList() {
      getStationList({}).then((res) => {
        this.list = res.data;
      });
    },
    // 弹窗确认
    handleOk() {
      if (this.stationList) {
        if (this.multipleValue.length == 0) {
          return this.$message.error({ content: "请选择电站!" });
        }
        this.$emit("update:stationList", this.multipleValue);

        this.$emit(
          "onSubmit",
          this.list.filter((item) =>
            this.multipleValue.some((ele) => ele == item.id)
          )
        );
        this.visible = false;
      } else {
        if (!this.value) {
          return this.$message.error({ content: "请选择电站!" });
        }
        this.$emit("update:id", this.value);
        this.$emit(
          "update:name",
          this.list.filter((item) => {
            return item.id == this.value;
          })[0].stationName
        );
        // 选择回调
        this.$emit(
          "onSubmit",
          this.list.filter((item) => {
            return item.id == this.value;
          })[0]
        );
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.add-power {
  .head-box {
    width: 100%;
    height: 64px;
    font-size: 20px;
    color: #252b3a;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-btn {
      /deep/.ant-btn {
        font-size: 12px;
      }

      .save-btn {
        margin-left: 8px;
      }
    }
  }

  /deep/.ant-drawer-body {
    height: calc(100vh - 100px);
  }

  .content-box {
    width: 100%;
    height: 100%;
    padding-top: 24px;

    .left {
      padding-right: 10px;

      /deep/.ant-form label {
        font-size: 12px;
        color: #00000073;
      }

      /deep/.ant-form-item-label {
        line-height: initial !important;
      }

      /deep/.ant-form-item {
        margin-bottom: 12px;
      }

      .tagBox-wrap {
        position: relative;
        border: 1px solid #d9d9d9;
        line-height: initial;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .flow {
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);

      li {
        position: relative;
        padding: 0 40px 60px 40px;

        &:before {
          position: absolute;
          display: block;
          content: "";
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          background-color: hsla(0, 0%, 50.2%, 0.6);
          border-radius: 50%;
        }

        &:after {
          position: absolute;
          display: block;
          content: "";
          top: 8px;
          bottom: -3px;
          left: 7px;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 1px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        .tip {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .header {
          position: relative;
          margin-bottom: 10px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }

          .close {
            position: absolute;
            top: -20%;
            right: -20%;
          }
        }
      }
    }
  }
}
</style>