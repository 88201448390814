<template>
    <div class="content" :class="collapsed ? 'width-min' : ''">
        <div class="back" @click="goBack">
            <a-icon type="left"/>
            <span class="name">回到电站列表</span>
        </div>
        <a-menu
                :open-keys.sync="openKeys"
                v-model="selectKey"
                mode="inline"
                class="menu-box"
                :inline-collapsed="collapsed"
                @click="handleClick"
        >
            <template v-for="menu in routes">
                <a-sub-menu
                        :key="menu.path"
                        v-if="menu.children && menu.path != '/station'"
                >
          <span slot="title"
          ><a-icon :type="menu.meta.icon"/><span>{{
              menu.meta.title
            }}</span></span
          >
                    <a-menu-item
                            :key="menuChild.path"
                            v-for="menuChild in menu.children"
                            @click="toPath(menuChild.path)"
                    >
                        {{ menuChild.meta.title }}
                    </a-menu-item>
                </a-sub-menu>
                <a-menu-item
                        :key="menu.path"
                        v-else-if="menu.children && menu.path == '/station'"
                        @click="toPath(menu.children[0].path)"
                >
                    <a-icon :type="menu.children[0].meta.icon"/>
                    {{ menu.children[0].meta.title }}
                </a-menu-item>
                <a-menu-item :key="menu.path" v-else @click="toPath(menu.path)">
                    <a-icon :type="menu.meta.icon"/>
                    {{ menu.meta.title }}
                </a-menu-item>
            </template>
        </a-menu>

        <div class="collpase" @click="toggleCollapsed">
            <a-icon type="vertical-right"/>
        </div>
    </div>
</template>
<script>
    import {stationAsyncRoutes} from "../../../router/index";
    import {stationChildList} from "@/api/device.js";

    export default {
        data() {
            return {
                openKeys: [""],
                stationAsyncRoutes,
                collapsed: false,
                selectKey: [],
                systemList: [],
                stationName: '' // 子系统电站名称
            };
        },
        computed: {
            routes() {
                return stationAsyncRoutes[0].children;
            },
        },
        mounted() {
            this.stationName = sessionStorage.getItem("stationName");
            this.selectKey = this.$store.state.Menu.menuKey;
            if (this.selectKey[0] != this.$route.path) {
                this.selectKey = [this.$route.path];
                this.$store.commit("Menu/updateMenuKey", this.$route.path);
            }
            this.getStationChildList()
        },
        methods: {
            getStationChildList() {
                stationChildList({
                    id: this.$route.query.id,
                }).then((res) => {
                    this.systemList = res.data;
                });
            },

            handleClick({key}) {
                this.$store.commit("Menu/updateMenuKey", key);
            },
            toggleCollapsed() {
                this.collapsed = !this.collapsed;
            },
            toPath(path) {
                let stationId = sessionStorage.getItem("stationId");
                this.$router.push({
                    path,
                    query: {
                        id: stationId,
                    },
                });
            },
            goBack() {
                this.$router.push({path: '/monitoring/powerstation'})
            }
        }
    };
</script>
<style lang="less" scoped>
    .name {
        white-space: nowrap;
    }

    .back {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #92959c;
        font-size: 12px;
        padding-left: 24px;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
            color: #1890ff;
        }

        .anticon {
            margin-right: 10px !important;
        }
    }

    .dropdown {
        margin-left: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }

    .content {
        width: 192px;
        height: 100%;
        transition: all 0.2s;
        position: relative;

        .menu-box {
        }

        .collpase {
            position: absolute;
            width: 12px;
            height: 66px;
            font-size: 12px;
            z-index: 2;
            color: #048fff;
            -webkit-clip-path: polygon(0 0, 100% 9%, 100% 93%, 0 100%);
            clip-path: polygon(0 0, 100% 9%, 100% 93%, 0 100%);
            background: #b9d7f1;
            right: -12px;
            top: 50%;
            display: flex;
            align-items: center;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background: #82c0f4;
                color: #fff;
            }
        }

        .ant-menu-item {
            font-size: 12px;
        }

        .ant-menu-submenu {
            /deep/ .ant-menu-submenu-title {
                font-size: 12px;
            }
        }

        /deep/ .ant-menu-inline-collapsed {
            width: 80px;
            overflow: hidden;
        }

        /deep/ .anticon {
            margin-right: 20px;
        }

        // background: #bfa;
    }

    .width-min {
        width: 80px;
    }
</style>
