import { requestToken } from '@/utils/request.js'

// 获取电站数据看板信息
export const getStationData = (data) => {
    return requestToken('/station/child', data)
}

// 电站子系统历史数据
export const getStationHistoryData = (data) => {
    return requestToken('/station/stationHistoryData', data)
}

// 添加子系统
export const saveSingle = (data) => {
    return requestToken('/station/saveSingle', data)
}

// 电站子系统逆变器排名
export const getInverterRanking = (data) => {
    return requestToken('/station/inverterRanking', data)
}

// 导出电站历史
export const downloadHistory = (data) => {
    return requestToken('/station/downloadHistory', data,{
        responseType: 'blob'
    })
}

