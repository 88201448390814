import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/utils/flexible.js'
import './utils/perssions'
import store from './store'
import router from './router'
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import Pagination from './components/pagination.vue'
import myTable from './components/my-table.vue'
import myDrop from './components/dropdown.vue'
import TemplateModal from './components/TemplateModal.vue'
import StationModal from './components/StationModal.vue'
import DeviceModal from './components/DeviceModal.vue'
import PoliceModal from './components/PoliceModal.vue'
import MemberModal from './components/MemberModal.vue'
import './assets/iconfont/iconfont.css'

Vue.component('Pagination', Pagination)
Vue.component('myTable', myTable)
Vue.component('myDrop', myDrop)
Vue.component('TemplateModal', TemplateModal) // 选择模板弹窗
Vue.component('StationModal', StationModal) // 选择电站弹窗
Vue.component('DeviceModal', DeviceModal) // 选择设备弹窗
Vue.component('PoliceModal', PoliceModal) // 选择设备弹窗
Vue.component('MemberModal', MemberModal) // 选择成员弹窗
Vue.prototype.echarts = echarts

Vue.config.productionTip = false;

Vue.use(Antd);


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')