import { setToken, getToken, removeToken } from "@/utils/auth.js";
import { loginApi } from "@/api/login.js";
const state = {
    uerInfo: {},
    token: getToken(),
    menus: []
}

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_USERINFO(state, uerInfo) {
        state.uerInfo = uerInfo
    },
    SET_MENUS(state, menus) {
        state.menus = menus
    }
}

const actions = {
    // 登录
    Login({ commit }, userInfo) {
        const telPhone = userInfo.telPhone
        const password = userInfo.password
        return new Promise((resolve, reject) => {
            loginApi({ password, telPhone })
                .then((res) => {
                    setToken(res.data.token)
                    commit('SET_USERINFO', res.data)
                    commit('SET_TOKEN', res.data.token)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then((res) => {
                    const user = res.user
                    // const avatar =
                    //     user.avatar == '' || user.avatar == null
                    //         ? require('@/assets/images/profile.jpg')
                    //         : process.env.VUE_APP_BASE_API + user.avatar
                    if (res.roles && res.roles.length > 0) {
                        // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                        commit('SET_PERMISSIONS', res.permissions)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', user.userName)
                    commit('SET_AVATAR', avatar)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // 退出系统
    LogOut({ commit, state }) {
        return new Promise((resolve, reject) => {

            commit('SET_TOKEN', '')
            commit('SET_MENUS', [])

            commit('SET_USERINFO', {})
            removeToken()
            // removeUserInfoID()
            resolve()
        })
    },


}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}