import service from '@/utils/request.js'

// 获取模板列表
export const getTemplateList = (data) => {
  return service({
    data,
    method: 'post',
    url: 'template/queryList'
  })
}
