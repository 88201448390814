<template>
    <a-config-provider :locale="zh_CN">
        <div class="max-box">
            <aside class="side">
                <slider-bar></slider-bar>
            </aside>
            <section class="section">
                <router-view></router-view>
            </section>
        </div>
    </a-config-provider>
</template>
<script>
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import SliderBar from "./components/SliderBar.vue";
    import {getStationData} from "@/api/station/main";

    export default {
        components: {
            SliderBar,
        },
        data() {
            return {
                zh_CN,
            };
        },
        methods: {
            // 获取电站详情数据
            async getStationData() {
                try {
                    let res = await getStationData({id: this.$route.query.id});
                    // 储存子系统电站名称
                    sessionStorage.setItem("stationName", res.data.stationEntity.stationName);
                } catch (err) {

                }
            },
        },
        mounted() {
            if (this.$route.query.id) {
                sessionStorage.setItem("stationId", this.$route.query.id);
            }
            this.getStationData()
        },
    };
</script>
<style lang="less" scoped>
    .max-box {
        display: flex;
        height: calc(100vh);
        background-color: #f0f0f1;
    }

    .side {
        height: 100%;
        padding-top: 10px;
        background-color: #ffffff;
    }

    .section {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
