<template>
  <div class="navbar">
    <div class="navbar-logo"><img src="../assets/logo/aq01.svg" alt="" /></div>
    <div class="right-operation">
      <div class="setting-box">
        <!-- <div class="wait-box mr-16" @click="openWait">
          <a-icon type="calendar" />
          <a-badge count="99" show-zero :offset="[10, -1]" :overflow-count="99">
            <span>待办</span>
          </a-badge>
        </div> -->
        <!-- <div class="follow-box mr-16">
          <a-icon type="star" />
          <span>我的关注</span>
        </div> -->
        <a-dropdown placement="bottomCenter">
          <div class="icon-box">
            <a-icon type="plus-circle" class="icon-size" />
          </div>
          <a-menu slot="overlay" style="top: 4px !important">
            <a-menu-item @click="toStation('/monitoring/powerstation')">
              <a href="javascript:;">新建电站</a>
            </a-menu-item>
            <a-menu-item @click="toStation('/operationMaintenance/workOrder')">
              <a href="javascript:;">提交工单</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <!-- <div class="icon-box">
          <a-badge count="0" show-zero :offset="[4, -1]" :overflow-count="99">
            <a-icon type="bell" class="icon-size" @click="handleOpen"></a-icon>
          </a-badge>
        </div> -->
        <a-dropdown placement="bottomCenter">
          <div class="icon-box">
            <a-icon type="question-circle" class="icon-size" />
          </div>
          <a-menu slot="overlay" style="top: 4px !important">
            <!-- <a-menu-item>
              <a href="javascript:;">我的客服</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;">操作手册</a>
            </a-menu-item> -->
            <a-menu-item>
              <a href="javascript:;">下载APP</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <div class="avatar-box">
          <a-dropdown :trigger="['click']">
            <img :src="getAvgHead" alt="" />
            <a-menu slot="overlay">
              <a-menu-item key="0">
                <div class="ava-box">
                  <img :src="getAvgHead" alt="" />
                  <div class="name-box">
                    <div class="name">{{ userMsg?.name }}</div>
                    <div>{{ userMsg?.roleName || "--" }}</div>
                  </div>
                </div>
              </a-menu-item>
              <a-menu-item key="1">
                <div class="setting-box" @click="toSetting">个人设置</div>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">
                <div class="setting-box" @click="logout">退出登录</div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>

    <!-- <a-drawer placement="right" :visible="visible" @close="onClose" width="900" :closable="false"
              :headerStyle="{ borderBottom: 'none', paddingBottom: '0' }" :bodyStyle="{
      background: '#EFEFF0',
      height: '100%',
      padding: '16px 12px 20px 12px',
    }" :drawerStyle="{ display: 'flex', flexDirection: 'column' }">
      <template #title>
        <div class="top-box">
          <div class="header">
            <span>消息</span>
            <div class="right-operation">
              <a-icon type="sync" class="reload-icon" />
              <div class="close-box" @click="onClose">
                <a-icon type="close" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="notice-list">

        <div class="item">
          <div class="pb105x">
            <span class="dpIB w80pct fsLv3">
              <span>演示账号发起的工单需要你接受</span>
            </span>
          </div>
          <div class="pb1x fsLv2">
            <span class="mr05x cSub">工单标题:</span>环境好家伙
          </div>
          <div class="pb1x fsLv2">
            <span class="mr05x cSub">编号:</span>5405
          </div>
          <div class="pb1x fsLv2">
            <span class="mr05x cSub">优先级:</span>普通
          </div>
          <div class="bdLight-top pt105x">
            <span style="color: rgb(248, 181, 30);">待接受</span>
            <span class="fR">查看详情</span>
          </div>
        </div>

      </div>

    </a-drawer> -->
  </div>
</template>
  <script>
import store from "../store/index";
import router from "../router/index";
import { getCurrentUser } from "@/api/user.js";
export default {
  data() {
    return {
      userMsg: null,
      visible: false,
    };
  },
  methods: {
    handleOpen() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    openWait() {
      this.$emit("waitDrawer", true);
    },
    toStation(path) {
      this.$router.push(path + "?nav=true");
    },
    toSetting() {
      const routeUrl = this.$router.resolve({
        path: "/setting/index",
      });
      window.open(routeUrl.href, "_blank");
    },
    getUserMsg() {
      getCurrentUser().then((res) => {
        console.log(res);
        this.userMsg = res.data;
      });
    },
    logout() {
      store.dispatch("User/LogOut").then((res) => {
        console.log("退出成功");
        router.push("/login");
      });
    },
  },
  computed: {
    getAvgHead() {
      return this.userMsg?.headImgUrl || require("../assets/defaultAvg.png");
    },
  },
  mounted() {
    this.getUserMsg();
  },
};
</script>
  <style lang="less" scoped>
.notice-list {
}
.top-box {
  padding-bottom: 16px !important;
  .header {
    height: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      color: #252b3a;
    }
    .right-operation {
      display: flex;
      //   justify-content: space-between;
      align-items: center;
      .reload-icon {
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: #048fff;
        }
      }
      .close-box {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-left: 16px;
        background: #ff7875;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          i {
            transform: rotate(90deg);
          }
        }
        i {
          color: #fff;
          transition: all 0.2s;
        }
      }
    }
  }
}
.navbar {
  height: 48px;
  width: 100%;
  background: #2b2e45;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  .navbar-logo {
    width: 108px;
    height: 30px;
    // background: url("../assets/logo/Untitled.svg");
    // background-size: 100% 100%;
  }
  .right-operation {
    height: 100%;
    display: flex;
    align-items: center;
    color: #ffffffa6;
    .setting-box {
      height: 24px;
      display: flex;
      align-items: center;
      .wait-box {
        border-radius: 24px;
        padding: 0 16px;
        background: #3c3e54;
        cursor: pointer;
        span {
          margin-left: 4px;
        }
      }
      .follow-box {
        border-radius: 24px;
        padding: 0 16px;
        background: #3c3e54;
        cursor: pointer;
        span {
          margin-left: 4px;
        }
      }
      .unread-box {
        width: 16px;
        height: 16px;
      }
      .icon-box {
        padding: 0 8px;
        cursor: pointer;
        &:hover {
          color: #048fff;
        }
        .icon-size {
          font-size: 16px;
        }
      }
      .avatar-box {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 24px;
        margin-left: 6px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}
/deep/.ant-badge-count {
  min-width: 13px !important;
  height: 13px !important;
  line-height: 13px;
  padding: 0 4px;
  //   min-width: max-content !important;
  //   min-height: max-content !important;
}
/deep/.ant-badge-multiple-words {
  padding: 0 4px;
}
/deep/.setting-box {
  padding: 0 20px;
  text-align: center;
}
/deep/.ava-box {
  display: flex;
  padding: 12px 15px;
  img {
    width: 48px;
    height: 48px;
    margin: 0 12px;
    border-radius: 50%;
  }
  .name-box {
    font-size: 14px;
    margin-left: 10px;
    .name {
      font-size: 18px;
    }
  }
}
</style>
