import { requestToken } from '@/utils/request.js'

// 查询标签列表
export const queryDeviceList = (data) => {
    return requestToken('/device/queryList', data)
}

// 查询设备总数
export const queryDeviceCount = (data) => {
    return requestToken('/device/queryCount', data)
}

//TTL：获取逆变器详情 /device/queryInverterDetails
export const queryInverterDetails = (data) => {
    return requestToken('/inverterElectric/querySingle', data)
}
//TTL获取交直流列表dcAmount
export const queryDcList = (data) => {
    return requestToken('/dcAmount/queryList', data)
}
//TTL获取交流列表acAmount
export const queryAcList = (data) => {
    return requestToken('/acAmount/queryList', data)
}
//TTL查询逆变器详情，详细信息
export const queryDeviceInverterDetail = (data) => {
    return requestToken('/device/queryInverterDetails', data)
}
//TTL：获取逆变器某一天详细数据 /device/queryInverterDetailList
export const queryInverterDetailList = (data) => {
    return requestToken('/inverterElectric/queryList', data)
}
// 获取设备历史数据 /device/historyData
export const deviceHistoryData = (data) => {
    return requestToken('/device/historyData', data)
}
// 获取设备历史数据 /device/dcHistoryData
export const dcHistoryData = (data) => {
    return requestToken('/device/dcHistoryData', data)
}
//动态获取所选楼顶的逆变器列表，计算总发电量，自己总功率归一化
export const getInverterList = (data) => {
    return requestToken('/device/getInverterList', data)
}

// 获取监控器详情 /device/queryMonitorDetails
export const queryMonitorDetails = (data) => {
    return requestToken('/device/queryMonitorDetails', data)
}

// 监控器 发电量热力图接口 /monitorAmount/amountMap
export const monitorAmount = (data) => {
    return requestToken('/monitorAmount/amountMap', data)
}
// 温度热力图 /monitorAmount/tempMap
export const monitorTempMap = (data) => {
    return requestToken('/monitorAmount/tempMap', data)
}
//编辑提交 /inverterComponentCapacity/saveSingle
export const capacitySaveSingle = (data) => {
    return requestToken('/inverterComponentCapacity/saveSingle', data)
}

// 获取编辑 /inverterComponentCapacity/queryList
export const capacityQueryList = (data) => {
    return requestToken('/inverterComponentCapacity/queryList', data)
}

// 获取设备的名称 /device/querySingle
export const querySingleName = (data) => {
    return requestToken('/device/querySingle', data)
}

// 获取设备连接信息
export const deviceConnection = (data) => {
    return requestToken('/device/connection', data)
}

// 子系统设备列表 /station/childList
export const stationChildList = (data) => {
    return requestToken('/station/childList', data)
}

// 监控器详情历史数据 /monitorAmount/monitorHistoryData
export const monitorHistoryData = (data) => {
    return requestToken('/monitorAmount/monitorHistoryData', data)
}

export const queryRealTimeList = (data) => {
    return requestToken('/monitorAmount/queryRealTimeList', data)
}

// 查询监控器实时发电数据列表
export const queryToDayList = (data) => {
    return requestToken('/monitorAmount/queryToDayList', data)
}

// 设备编辑 /device/updateSingle
export const deviceUpdate = (data) => {
    return requestToken('/device/updateSingle', data)
}


// 机器人
export const robotStart = (data) => {
    return requestToken('/robot/robotStart', data)
}

// 机器人详情 /robot/querySingle
export const robotDetail = (data) => {
    return requestToken('/robot/querySingle', data)
}

// 机器人设置回显 /robotStrategy/queryList
export const robotStrategy = (data) => {
    return requestToken('/robotStrategy/queryList', data)
}

// 机器人全局策略设置 /robotStrategy/saveSingle
export const robotStrategySet = (data) => {
    return requestToken('/robotStrategy/saveSingle', data)
}

// 机器人启动 /robotRun/start
export const robotRun = (data) => {
    return requestToken('/robotRun/start', data)
}

// 机器人启动 /robotRun/start
export const robotStop = (data) => {
    return requestToken('/robotRun/stop', data)
}

// 机器人复位 /robotRun/start
export const resettingRobot = (data) => {
    return requestToken('/robotRun/reset', data)
}

// 设置左停机位 /robot/leftPosition
export const leftPosition = (data) => {
    return requestToken('/robot/leftPosition', data)
}

// 设置右停机位 /robot/rightPosition
export const rightPosition = (data) => {
    return requestToken('/robot/rightPosition', data)
}

// 自动控制状态 /robotStrategy/autoControl
export const autoControl = (data) => {
    return requestToken('/robot/autoControl', data)
}

//查询机器人列表 /robot/queryList
export const robotQuerylist = (data) => {
    return requestToken('/robot/queryList', data)
}

// 气象站详情 /weatherStation/querySingle
export const weatherStation = (data) => {
    return requestToken('/weatherStation/querySingle', data)
}


// 设置机器人参数 /robotParam/saveSingle
export const robotParam = (data) => {
    return requestToken('/robotParam/saveSingle', data)
}

// 设置机器人名称
export const setRobotName = (data) => {
    return requestToken('/robot/updateSingle', data)
}

// 查看机器人参数 /robotParam/queryList
export const robotParamQuery = (data) => {
    return requestToken('/robotParam/querySingle', data)
}


// 逆变器发电历史 /device/download
export const historyDownload = (data) => {
    return requestToken('/device/download', data, {
        responseType: 'blob',
    })
}

// 获取机器人运动轨迹 /robotParam/getList
export const robotParamList = (data) => {
    return requestToken('/robotParam/getList', data)
}

//  机器人设备状态 /robotDashboard/deviceStatus
export const robotDashboardStatus = (data) => {
    return requestToken('/robotDashboard/deviceStatus', data)
}

//  设备统计数据 /robotDashboard/dataStatistics
export const robotDashboardDataStatistics = (data) => {
    return requestToken('/robotDashboard/dataStatistics', data)
}


// 机器人清扫作业 /robotDashboard/cleanWork
export const robotCleanWork = (data) => {
    return requestToken('/robotDashboard/cleanWork', data)
}

// 机器人出仓率 /robotDashboard/outRate
export const robotOutRate = (data) => {
    return requestToken('/robotDashboard/outRate', data)
}

// 机器人故障数 /robotDashboard/alarmNumber
export const robotAlarmNumber = (data) => {
    return requestToken('/robotDashboard/alarmNumber', data)
}

// 历史出仓率 /robotDashboard/historyOutRate
export const historyOutRate = (data) => {
    return requestToken('/robotDashboard/historyOutRate', data)
}

// 设备导出
export const downloadDevice = (data) => {
    return requestToken('/device/downloadDevice', data, {
        responseType: 'blob',
    })
}

// 查询总直流功率、总有功功率、发电量 实时数据 /device/dcPowerList
export const dcPowerList = (data) => {
    return requestToken('/device/dcPowerList', data)
}


// 获取电表详情 /device/queryMeterDetails
export const ammerterDetail = (data) => {
    return requestToken('/device/queryMeterDetails', data)
}

// 机器人告警管理 /alarm/queryRobotAlarmList
export const queryRobotAlarmList = (data) => {
    return requestToken('/alarm/queryRobotAlarmList', data)
}

// 获取优化器详情tab列表
export const getOptimizerTabList = (data) => {
    return requestToken('/optimizerSystem/queryList', data)
}

// 获取优化器详情光伏板列表
export const getOptimizerPanelList = (data) => {
    return requestToken('/optimizerPanel/queryList', data)
}

// 新增/编辑优化器详情光伏板 type saveSingle 新增 updateSingle 修改
export const saveOptimizerPanel = (type,data) => {
    return requestToken(`/optimizerPanel/${type}`, data)
}

// 新增/编辑优化器详情光伏板 type saveSingle 新增 updateSingle 修改
export const delOptimizerPanel = (data) => {
    return requestToken('/optimizerPanel/deleteSingle', data)
}

