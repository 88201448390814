<template>
  <a-config-provider :locale="zh_CN">
    <div class="max-box">
      <nav-bar @waitDrawer="waitDrawerOpen"></nav-bar>
      <div class="content">
        <div class="slider-box">
          <slider-bar></slider-bar>
        </div>
        <div class="main">
          <app-main></app-main>
        </div>
      </div>
      <wait-drawer
        @waitClose="waitDrawerClose"
        :waitShow="waitShow"
      ></wait-drawer>
    </div>
  </a-config-provider>
</template>
<script>
import NavBar from "./Navbar";
import SliderBar from "./SliderBar.vue";
import AppMain from "./Appmain.vue";
import WaitDrawer from "./components/Waitdrawer.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      waitShow: false,
      zh_CN,
    };
  },
  components: { NavBar, SliderBar, AppMain, WaitDrawer },
  methods: {
    waitDrawerOpen(value) {
      this.waitShow = true;
    },
    waitDrawerClose() {
      this.waitShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.max-box {
  .content {
    display: flex;
    height: calc(100vh - 48px);
    .slider-box {
    }
    .main {
      width: 100%;
      overflow: hidden;
      // background: #f60;
    }
  }
}
</style>