export function exportDown(res, fileName) {
    if (res.size == 0) {
        return this.$message({
            type: 'warning',
            message: '暂无导出数据'
        })
    }
    // type的格式可以可以设置，可以把appcation/json设置进去，然后设置导出的类型
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
    // 兼容ie和360浏览器
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  fileName需要指定后缀类型例：.doc
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        let url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)   // 删除节点
        window.URL.revokeObjectURL(url)  // 释放blob对象 
    }
}

export function debound(fn, delay) {
    let timer = null
    return function (...args) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(this, args)
        }, delay)
    }
}