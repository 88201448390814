import axios from 'axios'
import store from '../store'
import { getToken } from '@/utils/auth'
import { message, Modal } from 'ant-design-vue'
import NProgress from 'nprogress'
import router from '../router/index'
export let isRelogin = { show: false };
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 20000,

})
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
service.interceptors.request.use(config => {
    NProgress.start()
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
        config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    // if (config.method === 'get' && config.params) {
    //     console.log(config.params);
    //     let url = config.url + '?' + tansParams(config.params);
    //     url = url.slice(0, -1);
    //     config.params = {};
    //     config.url = url;
    // }

    return config
}, error => {
    NProgress.done()
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    NProgress.done()
    // 获取错误信息
    const msg = res.data.message
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    if (res.data === '000600') {
        if (!isRelogin.show) {
            isRelogin.show = true;
            Modal.confirm({
                title: '系统提示',
                content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
                okText: '重新登陆',
                cancelText: '取消',
                onOk: () => {
                    isRelogin.show = false;
                    console.log(store)
                    store.dispatch('User/LogOut').then(() => {
                        // location.href = currentUtl
                        router.push('/login')
                    })
                },
                onCancel: () => {
                    isRelogin.show = false;
                    console.log('Cancel');
                },
            })
            // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            //     confirmButtonText: '重新登录',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }
            // ).then(() => {
            //     isRelogin.show = false;
            //     store.dispatch('User/LogOut').then(() => {
            //         let currentUtl = location.href.split('/#/')[0]
            //         location.href = currentUtl
            //     })
            // }).catch(() => {
            //     isRelogin.show = false;
            // });
        }
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code != '000000') {
        message.error({
            content: msg,
        })
        return Promise.reject(new Error(msg))
    } else {
        return res.data
    }
},
    error => {
        NProgress.done()
        console.log('err' + error)
        let { message: msg } = error;
        if (msg == "Network Error") {
            msg = "后端接口连接异常";
        }
        else if (msg.includes("timeout")) {
            msg = "系统接口请求超时";
        }
        else if (msg.includes("Request failed with status code")) {
            msg = "系统接口" + msg.substr(msg.length - 3) + "异常";
        }
        message.error({
            content: msg,
        })
        return Promise.reject(error)
    }
)
export const requestToken = (url, data, config) => {
    const defaultParams = {
        token: getToken(),
        ...data
    };
    return service.post(url, defaultParams, config)
}

export default service